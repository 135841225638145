import React, { useCallback, useState } from "react";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import BoxTitle from "../../components/BoxTitle/BoxTitle.component";
import { DetailsField } from "../../components/DetailsField/DetailsField.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import { SearchBox } from "../../components/SearchBox/SearchBox.component";
import { SystemListItem } from "../../components/SystemListItem/SystemListItem.component";
import {
  APILabels,
  PillMessages,
  Placeholder,
  BoolValueLabels,
  SystemLabels,
} from "../../constants/labels";
import { Api } from "../../types/Api";
import { System } from "../../types/System";
import styles from "./apiDetails.module.scss";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";
import { Link } from "react-router-dom";
import { LINK_ENTITIES } from "../../constants/routes";
import { ScoreValue } from "../../components/ScoreValue/ScoreValue.component";
import { Loader } from "../../components/Loader/Loader.component";
import { SuggestionIconLabel } from "../../components/SuggestionIconLabel/SuggestionIconLabel.component";
import ReactModal from "react-modal";
import { CTAButton } from "../../components/CTAButton/CTAButton.component";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";

const detailsFilterArray = [
  "id",
  "name",
  "owner",
  "compliance",
  "deepProduct",
  "creationDate",
];

const filteredApiDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) => detailsFilterArray.includes(key));

const systemDetailsFilterArray = [
  "id",
  "name",
  "scope",
  "hosting",
  "platform",
  "serviceManager",
  "businessOwner",
  "recommendation",
  "criticality",
  "implementationStatus",
  "creationDate",
  "isNorthStar",
  "isActual",
  "isOpen",
  "openDate",
];

const filteredSystemDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) =>
    systemDetailsFilterArray.includes(key)
  );

export interface APIDetailsProps {
  api?: Api;
  system?: System;
  systems: System[];
  systemsSuggestions: System[];
  systemsFilter: string;
  apiLoading: boolean;
  systemsLoading: boolean;
  onSearch: Function;
  setSystem: Function;
}

export const APIDetailsComponent: React.FC<APIDetailsProps> = ({
  api,
  system,
  systems,
  systemsSuggestions,
  systemsFilter,
  apiLoading,
  systemsLoading,
  onSearch,
  setSystem,
}) => {
  const [selectedSystem, setSelectedSystem] = useState<System | null>();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);

  const apiDetailsKeys: string[] = filteredApiDetails(Object.keys(api || {}));

  const systemDetailsKeys: string[] = filteredSystemDetails(
    Object.keys(system || {})
  );

  const handleSearch = useCallback(
    (value: string | number) => {
      onSearch(value);
    },
    [onSearch]
  );

  const handleSetSystem = useCallback(
    (system: any) => {
      setSelectedSystem(system);
      setIsConfirmationModalOpen(true);
      // setSystem(api?.groupId, api?.id, systemCode);
    },
    [setIsConfirmationModalOpen, setSelectedSystem]
  );

  const handleModalDismiss = useCallback(() => {
    setSelectedSystem(null);
    setIsConfirmationModalOpen(false);
    // setSystem(api?.groupId, api?.id, systemCode);
  }, [setIsConfirmationModalOpen, setSelectedSystem]);

  const handleModalConfirm = useCallback(() => {
    setSelectedSystem(null);
    setIsConfirmationModalOpen(false);
    setSystem(api?.groupId, api?.id, selectedSystem?.systemCode);
  }, [
    api?.groupId,
    api?.id,
    selectedSystem,
    setSystem,
    setSelectedSystem,
    setIsConfirmationModalOpen,
  ]);

  const formatApiValues = useCallback(
     (name: string, value: any) => {
      if (!api) {
        return value;
      }
      const handlers: { [key: string]: (v: any) => any } = {
        compliance: (value: number) => <ScoreValue api={api} />,
        creationDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [api]
  );

  const formatSystemValues = useCallback(
     (name: string, value: any) => {
      if (!system) {
        return value;
      }
      const boolValueFormat = (boolValue: boolean): string =>
        boolValue ? BoolValueLabels.True : BoolValueLabels.False;
      const handlers: { [key: string]: (v: any) => any } = {
        isNorthStar: (value: boolean) => boolValueFormat(value),
        isActual: (value: boolean) => boolValueFormat(value),
        isOpen: (value: boolean) => boolValueFormat(value),
        openDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [system]
  );

  if (apiLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.apiDetails__wrapper}>
      <div className={styles.apiDetails_header}>
        <PageHeader
          bookmark="API"
          title={api?.name || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>

      <BorderedBox>
        <BoxTitle title="Details"></BoxTitle>
        <div className={styles.details_wrapper}>
          {!!api &&
            (apiDetailsKeys as Array<keyof typeof api>).map((apikey) => (
              <DetailsField
                key={apikey}
                handler={apikey}
                name={APILabels[apikey]}
                value={formatApiValues(apikey, api[apikey])}
              />
            ))}
        </div>
      </BorderedBox>

      <BorderedBox>
        <BoxTitle title="References"></BoxTitle>
        <a href={api?.url}>Exchange</a>
      </BorderedBox>

      <BorderedBox>
        <div className={styles.details__system_title}>
          <BoxTitle title="System"></BoxTitle>
          {!system && !systemsLoading && (
            <PillLabel
              variant={PillVariants.ERROR}
              text={PillMessages.missingSystem}
            />
          )}
          {!system && !systemsLoading && (
            <div className={styles.search__wrapper}>
              <SearchBox onChange={handleSearch} value={systemsFilter} />
            </div>
          )}

          {!!system && (
            <Link
              to={LINK_ENTITIES.system(system.systemCode)}
              className={styles.icon__link}
            >
              <Icon path={mdiEye} title={"View System details"} size={"2rem"} />
            </Link>
          )}
        </div>
        {systemsLoading && <Loader />}
        {!!system && (
          <div className={styles.details_wrapper}>
            {(systemDetailsKeys as Array<keyof typeof system>).map(
              (systemkey) => (
                <DetailsField
                  key={systemkey}
                  handler={systemkey}
                  name={SystemLabels[systemkey]}
                  value={formatSystemValues(systemkey, system[systemkey])}
                />
              )
            )}
          </div>
        )}
        {!system && !systemsLoading && (
          <div className={styles.pill_area}>
            <PillLabel text={PillMessages.pleaseSelectSystem} />
            <SuggestionIconLabel withLabel />
          </div>
        )}

        {!system && !systemsLoading && (
          <div className={styles.systems__list}>
            {!systemsFilter &&
              systemsSuggestions.map((system: System) => (
                <SystemListItem
                  key={`${system.systemId}-${system.name}`}
                  system={system}
                  isSuggestion={true}
                  ctaAction={() => handleSetSystem(system)}
                />
              ))}
            {systemsFilter &&
              systems.map((system: System) => (
                <SystemListItem
                  key={`${system.systemId}-${system.name}`}
                  system={system}
                  isSuggestion={false}
                  ctaAction={() => handleSetSystem(system)}
                />
              ))}
          </div>
        )}
      </BorderedBox>
      <ReactModal
        ariaHideApp={false}
        isOpen={isConfirmationModalOpen}
        className={styles.modal}
        overlayClassName={styles.overlay}
        onRequestClose={handleModalDismiss}
      >
        <div className={styles.modal__title}>Are you sure?</div>
        <div className={styles.modal__content}>
          {" "}
          Are you sure you want to associate <b>{selectedSystem?.name}</b> with
          this API?{" "}
        </div>
        <div className={styles.modal__buttons}>
          <CTAButton isSecondary onClick={handleModalDismiss}>
            Cancel
          </CTAButton>
          <CTAButton onClick={handleModalConfirm}>Confirm</CTAButton>
        </div>
      </ReactModal>
    </div>
  );
};
