import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitHandler } from "react-hook-form";
import { CreateReviewComponent } from "./CreateReview.component";
import {
  ApiReviewNewInterface,
  ApiReviewTogether,
} from "../../types/ApiReview";
import { ApiException } from "../../types/ApiException";
import {
  apisLoadingSelector,
  apisReviewMessageSelector,
  apisReviewMessageLoadSelector,
  apiListReviewTypeSolutionSelector,
  apiListReviewTypeAPISpecSelector,
} from "../../store/apisReview/selectors";
import { apiListSelector } from "../../store/apis/selectors";
import {
  authRoleMessageSelector,
  tokenSelector,
} from "../../store/auth/selectors";
import {
  listApisReviewTypeSolutionAction,
  listApisReviewTypeAPISpecAction,
  postAction,
} from "../../store/apisReview/actions";
import { listApisAllAction } from "../../store/apis/actions";
import { withAuth } from "../../components/withAuth/withAuth";
import { postExceptionAction } from "../../store/apisException/actions";

const CreateReviewContainer: React.FC = () => {
  const dispatch = useDispatch();
  const loading = useSelector(apisLoadingSelector);
  const reviewMessage = useSelector(apisReviewMessageSelector);
  const loadMessage = useSelector(apisReviewMessageLoadSelector);
  const [selectedReview, setSelectedReview] = useState("");
  const apisReviewTypeSolution = useSelector(apiListReviewTypeSolutionSelector);
  const apisReviewTypeApiSpec = useSelector(apiListReviewTypeAPISpecSelector);
  const apis = useSelector(apiListSelector);
  const deploymentTarget = [
    { value: "CH", name: "CloudHub" },
    { value: "RTF", name: "RTF" },
  ];
  const token = useSelector(tokenSelector);
  const isRoleAllowed = useSelector(authRoleMessageSelector);

  useEffect(() => {
    dispatch(listApisReviewTypeSolutionAction());
    dispatch(listApisReviewTypeAPISpecAction());
    dispatch(listApisAllAction("All", "All", ""));
  }, [dispatch]);

  const formSubmitHandler: SubmitHandler<ApiReviewTogether> = (
    data: ApiReviewTogether,
    e: any
  ) => {
    if (selectedReview === "exception") {
      const toValues: any = Object.values(data as ApiException);
      dispatch(postExceptionAction(toValues[0], token));
    } else {
      dispatch(postAction(data as ApiReviewNewInterface, token));
    }
  };

  const props = {
    apisReviewTypeSolution,
    apisReviewTypeApiSpec,
    apis,
    deploymentTarget,
    selectedReview,
    setSelectedReview,
    loading,
    reviewMessage,
    loadMessage,
    isRoleAllowed,
    onSubmit: formSubmitHandler,
  };

  return <CreateReviewComponent {...props} />;
};

export default withAuth(CreateReviewContainer);
