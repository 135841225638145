import React, { useCallback } from "react";
import styles from "./detailsReviewField.module.scss";
import { DetailsField } from "../DetailsField/DetailsField.component";
import {
  ReviewLabels,
  StatusValueLabels,
  Placeholder,
} from "../../constants/labels";
import { ApiReviewNewInterface } from "../../types/ApiReview";

const reviewFilterArray = [
  "apiId",
  "groupId",
  "reviewType",
  "jiraTicket",
  "qaReviewUrl",
  "solutionDocumentUrl",
  "version",
  "assignee",
  "approvedDate",
  "approved",
  "createdBy",
  "createdDate",
  "lastModifiedBy",
  "lastModifiedDate",
];

const filteredReviewDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) => reviewFilterArray.includes(key));

export interface DetailsReviewFieldProps {
  apiLatestReview?: ApiReviewNewInterface
}

export const DetailsReviewField: React.FC<DetailsReviewFieldProps> = ({
  apiLatestReview,
}) => {
  const reviewDetailsKeys: string[] = filteredReviewDetails(
    Object.keys(apiLatestReview || {})
  );
  
  const formatApiValues = useCallback(
    (name: string, value: any) => {
      if (!apiLatestReview) {
        return value;
      }
      const toApprovedFormat = (approvedStatus: boolean): string => {
        switch (approvedStatus) {
          case true:
            return StatusValueLabels.True;
          case false:
            return StatusValueLabels.False;
          default:
            return StatusValueLabels.ToBeReviewed;
        }
      };
      const formatJiraTicketId = (ticketId: string) => {
        if (ticketId === null || ticketId === Placeholder.NotAvailable) {
          return <span>{Placeholder.NotAvailable}</span>;
        }
        const isJiraTicket = ticketId.startsWith("G");
        const jiraLink = isJiraTicket
          ? (ticketId = `https://jira.app.pconnect.biz/browse/${ticketId}`)
          : ticketId;
        const displayText = ticketId.startsWith("https")
          ? ticketId.split("/")[4]
          : ticketId;
        return (
          <a className={styles.history_Link} href={jiraLink} title={ticketId}>
            {displayText}
          </a>
        );
      };
      const handlers: { [key: string]: (v: any) => any } = {
        approved: toApprovedFormat,
        solutionDocumentUrl: (value: string) => (
          <a className={styles.history_Link} href={value} title={value}>
            {value === null ? Placeholder.NotAvailable : "Link"}
          </a>
        ),
        qaReviewUrl: (value: string) => (
          <a className={styles.history_Link} href={value} title={value}>
            {value === null ? Placeholder.NotAvailable : "Link"}
          </a>
        ),
        jiraTicket: formatJiraTicketId,
        approvedDate: (value: string) => new Date(value).toUTCString(),
        createdDate: (value: string) => new Date(value).toUTCString(),
        lastModifiedDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [apiLatestReview]
  );

  return (
    <div className={styles.review_wrapper}>
      <div>
        <div className={styles.wrapper}>
          {!!apiLatestReview &&
            (reviewDetailsKeys as Array<keyof typeof apiLatestReview>).map(
              (apikey) => (
                <DetailsField
                  key={apikey}
                  handler={apikey}
                  name={ReviewLabels[apikey]}
                  value={formatApiValues(apikey, apiLatestReview[apikey])}
                />
              )
            )}
        </div>
      </div>
    </div>
  );
};
