import React, { ReactNode } from "react";
import {
  Controller,
  Control,
  RegisterOptions,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import {
  TextField,
  FormControl,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Autocomplete, AutocompleteRenderOptionState } from "@mui/material";
import styles from "./formInputs.module.scss";

interface FormInputsProps {
  name: string;
  control: Control<FieldValues, any>;
  errors?: FieldErrors<FieldValues>;
  label: string;
  id?: string;
  rules?: RegisterOptions;
  defaultValue?: string | number | boolean | null;
  options?: any[];
  getOptionLabel?: (option: any) => string;
  value?: any | null;
  isOptionEqualToValue?: (option: any, value: any) => boolean;
  noOptionsText?: string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    state: AutocompleteRenderOptionState
  ) => ReactNode;
  onChange?: (value: any | null) => void;
  freeSolo?: boolean;
}

const FormInputsString: React.FC<FormInputsProps> = ({
  name,
  control,
  errors,
  label,
  id,
  rules = { required: true },
  defaultValue = "",
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          id={id}
          variant="outlined"
          required
          fullWidth
          error={!!errors?.[name]}
          helperText={errors?.[name] ? "Required" : ""}
          margin="dense"
        />
      )}
    />
  );
};

const FormInputsParseStringToNumber: React.FC<FormInputsProps> = ({
  name,
  control,
  errors,
  label,
  id,
  rules = { required: true },
  defaultValue = "",
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(e: any) => field.onChange(parseInt(e.target.value))}
          label={label}
          id={id}
          variant="outlined"
          type="number"
          required
          fullWidth
          error={!!errors?.[name]}
          helperText={errors?.[name] ? "Required" : ""}
          margin="dense"
        />
      )}
    />
  );
};

const FormInputsAutoCompleteDropdown: React.FC<FormInputsProps> = ({
  name,
  control,
  defaultValue = "",
  rules,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  noOptionsText,
  renderOption,
  label,
  errors,
  onChange,
  freeSolo = false,
}) => {

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => (
        <Autocomplete
          {...field}
          id={name}
          className={styles.autoComplete}
          options={options!}
          getOptionLabel={getOptionLabel}
          value={field.value}
          isOptionEqualToValue={isOptionEqualToValue}
          noOptionsText={noOptionsText}
          renderOption={renderOption}
          freeSolo={freeSolo}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              className={styles.textField_dropdown}
              key={params.id}
              label={label}
              error={!!errors?.[name]}
              helperText={errors?.[name] ? "Required" : ""}
              variant="outlined"
              onChange={(e) => {
                const newValue = e.target.value;
                field.onChange(newValue);
                if (onChange) {
                  onChange(newValue);
                }
              }}
            />
          )}
          onChange={(event, newValue) => {
            const valueToSet = newValue?.id || newValue?.value || newValue;
            field.onChange(valueToSet);
            if (onChange) {
              onChange(valueToSet);
            }
          }}
          sx={{
            "& .MuiAutocomplete-clearIndicator": {
              display: "none",
            },
          }}
        />
      )}
    />
  );
};

const FormInputSwitch: React.FC<FormInputsProps> = ({
  name,
  control,
  defaultValue = false,
  label,
  onChange,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <FormControl
          id={styles.formReview_Switch}
          fullWidth
          margin="dense"
          variant="outlined"
        >
          <FormControlLabel
            label={label}
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              margin: "0",
              width: "100%",
              justifyContent: "space-between",
            }}
            classes={{ label: styles.formReview_Switch_Label }}
            control={
              <Switch
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  if (onChange) {
                    onChange(e.target.checked);
                  }
                }}
                checked={field.value}
                sx={{
                  "& .Mui-checked": {
                    color: "#0f7563 !important",
                  },
                  "& .Mui-checked+.MuiSwitch-track": {
                    backgroundColor: "#0f7563 !important", //Unable to access value, need to override
                  }
                }}
              />
            }
          />
        </FormControl>
      )}
    />
  );
};

export {
  FormInputsString,
  FormInputsParseStringToNumber,
  FormInputsAutoCompleteDropdown,
  FormInputSwitch,
};
