import React, { useCallback } from "react";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import BoxTitle from "../../components/BoxTitle/BoxTitle.component";
import { DetailsField } from "../../components/DetailsField/DetailsField.component";
import { Loader } from "../../components/Loader/Loader.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";
import {
  Placeholder,
  SystemLabels,
  BoolValueLabels,
} from "../../constants/labels";
import { System } from "../../types/System";
import styles from "./systemDetails.module.scss";

const detailsFilterArray = [
  "id",
  "name",
  "scope",
  "hosting",
  "platform",
  "serviceManager",
  "businessOwner",
  "recommendation",
  "criticality",
  "implementationStatus",
  "creationDate",
  "isNorthStar",
  "isActual",
  "isOpen",
  "openDate",
];

const filteredDetails = (detailsLabelArray: string[]): string[] =>
  detailsLabelArray.filter((key: string) => detailsFilterArray.includes(key));

export interface SystemDetailsComponentProps {
  system?: System;
  systemLoading: boolean;
}

export const SystemDetailsComponent: React.FC<SystemDetailsComponentProps> = ({
  system,
  systemLoading,
}) => {
  const systemDetailsKeys: string[] = filteredDetails(
    Object.keys(system || {})
  );

  const formatValues = useCallback(
    (name:string, value:any) => {
      if (!system) {
        return value;
      }
      const boolValueFormat = (boolValue: boolean): string =>
      boolValue ? BoolValueLabels.True : BoolValueLabels.False;
      const handlers: { [key: string]: (v: any) => any } = {
        isNorthStar: (value: boolean) => boolValueFormat(value),
        isActual: (value: boolean) => boolValueFormat(value),
        isOpen: (value: boolean) => boolValueFormat(value),
        openDate: (value: string) => new Date(value).toUTCString(),
      };
      return handlers[name] ? handlers[name](value) : value;
    },
    [system]
  );

  if (systemLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.systemDetails__wrapper}>
      <div className={styles.system_header}>
        <PageHeader
          bookmark="System"
          title={system?.name || Placeholder.NotAvailable}
          subtitle={system?.platform || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>
      <BorderedBox>
        <div className={styles.details__system_title}>
          <BoxTitle title="Details"></BoxTitle>

          {!!system && (
            <div className={styles.details_wrapper}>
              {(systemDetailsKeys as Array<keyof typeof system>).map(
                (systemkey) => (
                  <DetailsField
                    key={systemkey}
                    handler={systemkey}
                    name={SystemLabels[systemkey]}
                    value={formatValues(systemkey, system[systemkey])}
                  />
                )
              )}
            </div>
          )}
        </div>
      </BorderedBox>
    </div>
  );
};
