import React, { useState } from "react";
import { format } from "date-fns";
import styles from "./formReview.module.scss";
import { useFormContext, Controller } from "react-hook-form";
import { ApiReview } from "../../types/ApiReview";
import { TextField, FormControl, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Autocomplete,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/material";
import { DeploymentTarget } from "../../types/ApiException";

export interface FormReviewProps {
  selectedReview: string;
  apisReviewTypeSolution: ApiReview[];
  apisReviewTypeApiSpec: ApiReview[];
  deploymentTarget: DeploymentTarget[];
}

const useStyles = makeStyles({
  switch: {
    "& .Mui-checked": {
      color: "#0f7563 !important",
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#0f7563 !important", //Unable to access value, need to override
    },
  },
});

export const FormReview: React.FC<FormReviewProps> = ({
  selectedReview,
  apisReviewTypeSolution,
  apisReviewTypeApiSpec,
  deploymentTarget,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [valueAssetID] = useState();
  const [valueSolutionDesignPageID] = useState();
  const [deploymentTargetSelect, setDeploymentTargetSelect] = useState("");
  const classes = useStyles();

  const getDaysInMonth = (year: number, month: number) =>
    new Date(year, month, 0).getDate();

  const addMonths = (input: any, months: number) => {
    input.setMonth(input.getMonth() + months);
    input.setDate(
      Math.min(
        input.getDate(),
        getDaysInMonth(input.getFullYear(), input.getMonth() + 1)
      )
    );
    const formatDate = format(input.valueOf(), `yyyy-MM-dd'T'HH:mm:ss`);
    return formatDate;
  };

  return (
    <div>
      {selectedReview === "exception" && (
        <>
          <Controller
            name={`${selectedReview}.deepProduct`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                autoFocus
                label="DEEP Product"
                id="deepProduct"
                required
                variant="outlined"
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.deepProduct}
                helperText={
                  (errors[`${selectedReview}`] as any)?.deepProduct
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.jiraTicketId`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Jira Ticket"
                id="jiraTicketId"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.artefactId`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Artefact ID"
                id="artefactId"
                variant="outlined"
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.artefactId}
                helperText={
                  (errors[`${selectedReview}`] as any)?.artefactId
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.codeVersion`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Code Version"
                id="codeVersion"
                variant="outlined"
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.codeVersion}
                helperText={
                  (errors[`${selectedReview}`] as any)?.codeVersion
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.assetId`}
            control={control}
            defaultValue={null}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id={`${selectedReview}.assetId`}
                className={styles.autoComplete}
                options={apisReviewTypeApiSpec}
                getOptionLabel={(option) =>
                  `${option.review.apiSpec.uniqueAssetId}`
                }
                value={valueAssetID}
                isOptionEqualToValue={(option, value) =>
                  option.review.apiSpec.uniqueAssetId ===
                  value.review.apiSpec.uniqueAssetId
                }
                noOptionsText="Nothing Found"
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props} key={props.id}>
                      {option.review.apiSpec.uniqueAssetId}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    className={styles.textField_dropdown}
                    key={params.id}
                    label="Asset ID"
                    error={!!(errors[`${selectedReview}`] as any)?.assetId}
                    helperText={
                      (errors[`${selectedReview}`] as any)?.assetId
                        ? "Required"
                        : ""
                    }
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  field.onChange(value?.apiId);
                }}
              />
            )}
          />
          <Controller
            name={`${selectedReview}.assetVersion`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                required
                label="Asset Version"
                id="assetVersion"
                variant="outlined"
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.assetVersion}
                helperText={
                  (errors[`${selectedReview}`] as any)?.assetVersion
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.solutionUrl`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Solution Design URL"
                id="solutionDesignUrl"
                variant="outlined"
                required
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.solutionUrl}
                helperText={
                  (errors[`${selectedReview}`] as any)?.solutionUrl
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.solutionDesignPageId`}
            control={control}
            defaultValue={null}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Autocomplete
                {...field}
                id={`${selectedReview}.solutionDesignPageId`}
                className={styles.autoComplete}
                options={apisReviewTypeSolution}
                getOptionLabel={(option) =>
                  `${option.review.solutionDesign.uniqueSolutionId}`
                }
                value={valueSolutionDesignPageID}
                isOptionEqualToValue={(option, value) =>
                  option.review.solutionDesign.uniqueSolutionId ===
                  value.review.solutionDesign.uniqueSolutionId
                }
                noOptionsText="Nothing Found"
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props} key={props.id}>
                      {option.review.solutionDesign.uniqueSolutionId}
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    className={styles.textField_dropdown}
                    key={params.id}
                    label="Solution Design Page ID"
                    id={styles.formReview_Dropdown}
                    error={
                      !!(errors[`${selectedReview}`] as any)
                        ?.solutionDesignPageId
                    }
                    helperText={
                      (errors[`${selectedReview}`] as any)?.solutionDesignPageId
                        ? "Required"
                        : ""
                    }
                    variant="outlined"
                  />
                )}
                onChange={(event, value) => {
                  field.onChange(value?.review.solutionDesign.reviewId);
                }}
              />
            )}
          />
          <Controller
            name={`${selectedReview}.groupId`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Group ID"
                id="groupId"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.version`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Version"
                id="version"
                required
                variant="outlined"
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.version}
                helperText={
                  (errors[`${selectedReview}`] as any)?.version
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.approverId`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Approver Name"
                id="approverId"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.approvalDate`}
            control={control}
            defaultValue={format(new Date().valueOf(), `yyyy-MM-dd'T'HH:mm:ss`)}
            render={({ field }) => (
              <TextField
                {...field}
                type="datetime-local"
                label="Approval Date"
                id="approvalDate"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.approved`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControl
                {...field}
                id={styles.formReview_Switch}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                <label className={styles.formReview_Switch_Label}>
                  Approved
                </label>
                <Switch
                  className={classes.switch}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              </FormControl>
            )}
          />
          <Controller
            name={`${selectedReview}.lastUpdateDate`}
            control={control}
            defaultValue={format(new Date().valueOf(), `yyyy-MM-dd'T'HH:mm:ss`)}
            render={({ field }) => (
              <TextField
                {...field}
                type="datetime-local"
                label="Last Update Date"
                id="lastUpdateDate"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.lastUpdatedBy`}
            control={control}
            defaultValue=""
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Last Updated By"
                id="lastUpdatedBy"
                required
                variant="outlined"
                fullWidth
                error={!!(errors[`${selectedReview}`] as any)?.lastUpdatedBy}
                helperText={
                  (errors[`${selectedReview}`] as any)?.lastUpdatedBy
                    ? "Required"
                    : ""
                }
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.approvalDescription`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label="Approval Description"
                id="approvalDescription"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.approvalEndDate`}
            control={control}
            defaultValue={addMonths(new Date(), 6)}
            render={({ field }) => (
              <TextField
                {...field}
                type="datetime-local"
                label="Approval End Date"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
          <Controller
            name={`${selectedReview}.sizingException`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControl
                {...field}
                id={styles.formReview_Switch}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                <label className={styles.formReview_Switch_Label}>
                  Sizing Exception
                </label>
                <Switch
                  className={classes.switch}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              </FormControl>
            )}
          />
          <Controller
            name={`${selectedReview}.solutionDesignException`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControl
                {...field}
                id={styles.formReview_Switch}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                <label className={styles.formReview_Switch_Label}>
                  Solution Design Exception
                </label>
                <Switch
                  className={classes.switch}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              </FormControl>
            )}
          />
          <Controller
            name={`${selectedReview}.apiSpecificationException`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControl
                {...field}
                id={styles.formReview_Switch}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                <label className={styles.formReview_Switch_Label}>
                  API Specification Exception
                </label>
                <Switch
                  className={classes.switch}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              </FormControl>
            )}
          />
          <Controller
            name={`${selectedReview}.apiCodeException`}
            control={control}
            defaultValue={false}
            render={({ field }) => (
              <FormControl
                {...field}
                id={styles.formReview_Switch}
                fullWidth
                margin="dense"
                variant="outlined"
              >
                <label className={styles.formReview_Switch_Label}>
                  Application Code Exception
                </label>
                <Switch
                  className={classes.switch}
                  onChange={(e) => field.onChange(e.target.checked)}
                  checked={field.value}
                />
              </FormControl>
            )}
          />
          <div className={styles.deploymentTarget}>
            <Controller
              name={`${selectedReview}.approvedDeploymentTarget`}
              control={control}
              defaultValue={deploymentTargetSelect}
              render={({ field }) => (
                <RadioGroup
                  className={styles.approvedDeploymentTarget}
                  aria-label="Deployment Target"
                  value={deploymentTargetSelect}
                  onChange={(e: any) =>
                    setDeploymentTargetSelect(e.target.value)
                  }
                  name="DeploymentTarget"
                >
                  <FormLabel>Deployment Target</FormLabel>
                  {deploymentTarget.map((deploymentItem) => (
                    <FormControlLabel
                      {...field}
                      key={deploymentItem.value}
                      value={deploymentItem.value}
                      control={<Radio />}
                      label={deploymentItem.name}
                    />
                  ))}
                </RadioGroup>
              )}
            />
            {deploymentTargetSelect === "CH" && (
              <div>
                <Controller
                  name={`${selectedReview}.approvedWorkerSize`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus
                      label="Approved Worker Size"
                      id="approvedWorkerSize"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
                <Controller
                  name={`${selectedReview}.approvedWorkerNumber`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Approved Worker Number"
                      id="approvedWorkerNumber"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
              </div>
            )}
            {deploymentTargetSelect === "RTF" && (
              <div>
                <Controller
                  name={`${selectedReview}.approvedReplicas`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoFocus
                      label="Approved Number of Replicas"
                      id="approvedNumberOfReplicas"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
                <Controller
                  name={`${selectedReview}.approvedCpuLimit`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Approved CPU Limit"
                      id="approvedCPULimit"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
                <Controller
                  name={`${selectedReview}.approvedCpuReserved`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Approved CPU Reserved"
                      id="approvedCPUReserved"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
                <Controller
                  name={`${selectedReview}.approvedMemory`}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Approved Memory"
                      id="approvedMemory"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  )}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
