import React, { useEffect, useState } from "react";
import { APIReviewListComponent } from "./APIReviewList.component";
import { useDispatch, useSelector } from "react-redux";
import {
  apiListSelector,
  apisFilterValueSelector,
  apisLoadingSelector,
  apisDeepProductSelector,
} from "../../store/apis/selectors";
import { apiListReviewSelector } from "../../store/apisReview/selectors";
import {
  apisExceptionStatusUniqueValuesSelector,
  apisListExceptionSelector,
  apisListExceptionStatusSelector,
} from "../../store/apisException/selectors";
import {
  listApisAllAction,
  setApisFilterValueAction,
} from "../../store/apis/actions";
import { listApisReviewAction } from "../../store/apisReview/actions";
import {
  listApisExceptionAction,
  listApisExceptionStatusAction,
} from "../../store/apisException/actions";
import { withAuth } from "../../components/withAuth/withAuth";

const APIReviewListContainer: React.FC = () => {
  const dispatch = useDispatch();

  const loading = useSelector(apisLoadingSelector);
  const apis = useSelector(apiListSelector);
  const apisDeepProduct = useSelector(apisDeepProductSelector);
  const apisExceptionStatusUniqueValues = useSelector(
    apisExceptionStatusUniqueValuesSelector
  );
  const apisReview = useSelector(apiListReviewSelector);
  const apisException = useSelector(apisListExceptionSelector);
  const apisExceptionStatus = useSelector(apisListExceptionStatusSelector);
  const searchValue = useSelector(apisFilterValueSelector);
  const onSearch = (value: string) => dispatch(setApisFilterValueAction(value));
  const [selectedEnv, setSelectedEnv] = useState("All");
  const [selectedlayer, setSelectedLayer] = useState("All");
  const [selectedDeepProduct, setSelectedDeepProduct] = useState("");
  const [
    selectedExceptionStatusUniqueValue,
    setSelectedExceptionStatusUniqueValue,
  ] = useState("");

  useEffect(() => {
    dispatch(listApisReviewAction());
    dispatch(
      listApisAllAction(selectedlayer, selectedEnv, selectedDeepProduct)
    );
    dispatch(listApisExceptionAction());
    dispatch(listApisExceptionStatusAction());
  }, [dispatch, selectedlayer, selectedEnv, selectedDeepProduct]);

  const props = {
    apis,
    apisDeepProduct,
    apisReview,
    apisException,
    apisExceptionStatus,
    apisExceptionStatusUniqueValues,
    searchValue,
    onSearch,
    selectedEnv,
    setSelectedEnv,
    selectedlayer,
    setSelectedLayer,
    selectedDeepProduct,
    setSelectedDeepProduct,
    selectedExceptionStatusUniqueValue,
    setSelectedExceptionStatusUniqueValue,
    loading,
  };

  return <APIReviewListComponent {...props} />;
};

export default withAuth(APIReviewListContainer);
