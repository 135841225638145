import { call, put, select, takeEvery } from "redux-saga/effects";
import { ApiReview, ApiReviewNewInterface, ApiReviewTogether } from "../../types/ApiReview";
import { PillMessages, PostMessage } from "../../constants/labels";
import { tokenSelector } from "../../store/auth/selectors";
import {
  API_ACTIONS,
  PostActionType,
  FetchApiLatestReviewActionType,
  FetchApisReviewListByIdActionType,
  setApisLoadingAction,
  setApisMessageAction,
  setApisMessageLoadAction,
  setListApisReviewAction,
  setListApisReviewTypeSolutionAction,
  setListApisReviewTypeAPISpecAction,
  setSelectedApiLatestReviewAction,
  setSelectedApiReviewByIdAction,
} from "./actions";
import {
  getApisReview,
  getApisReviewById,
  getApiLatestReviewById,
  getApisReviewByReviewTypeSolution,
  getApisReviewByReviewTypeAPISpec,
  postApisReview,
} from "./requests";

export function* listApisReviewSaga() {
  try {
    yield put(setApisLoadingAction(true));
    const result: ApiReview[] = yield call(getApisReview);
    yield put(setListApisReviewAction(result));
  } catch (e) {
    yield put(setApisLoadingAction(false));
  }
}

export function* listApisReviewTypeSolutionSaga() {
  try {
    yield put(setApisLoadingAction(true));
    const result: ApiReview[] = yield call(getApisReviewByReviewTypeSolution);
    yield put(setListApisReviewTypeSolutionAction(result));
  } catch (e) {
    yield put(setApisLoadingAction(false));
    yield put(setApisMessageLoadAction(PillMessages.apiNotLoaded));
  }
}

export function* listApisReviewTypeAPISpecSaga() {
  try {
    yield put(setApisLoadingAction(true));
    const result: ApiReview[] = yield call(getApisReviewByReviewTypeAPISpec);
    yield put(setListApisReviewTypeAPISpecAction(result));
  } catch (e) {
    yield put(setApisLoadingAction(false));
    yield put(setApisMessageLoadAction(PillMessages.apiNotLoaded));
  }
}

export function* postReviewSaga({ payload: { token, data } }: PostActionType) {
  try {
    while (token === undefined || token === "") {
      token = yield select(tokenSelector);
    }
    const result: ApiReviewTogether = yield call(postApisReview, token, data);
    yield put(setApisMessageAction(PostMessage.successfulSubmitted));
    return result;
  } catch (e) {
    yield put(setApisMessageAction(PostMessage.errorSubmitted));
  }
}

export function* fetchApisReviewListByIdSaga({
  payload: { groupId, apiId },
}: FetchApisReviewListByIdActionType) {
  yield put(setApisLoadingAction(true));
  const result: ApiReviewNewInterface[] = yield call(getApisReviewById, groupId, apiId);
  yield put(setSelectedApiReviewByIdAction(result));
}


export function* fetchApiLatestReviewSaga({
  payload: { groupId, apiId },
}: FetchApiLatestReviewActionType) {
  yield put(setApisLoadingAction(true));
  const result: ApiReviewNewInterface[] = yield call(getApiLatestReviewById, groupId, apiId);
  yield put(setSelectedApiLatestReviewAction(result));
}

export function* apisReviewSaga() {
  yield takeEvery(API_ACTIONS.LIST_REVIEW, listApisReviewSaga);
  yield takeEvery(API_ACTIONS.FETCH_LIST_REVIEW_BY_ID, fetchApisReviewListByIdSaga);
  yield takeEvery(API_ACTIONS.FETCH_ONE_LATEST_REVIEW, fetchApiLatestReviewSaga);
  yield takeEvery(API_ACTIONS.LIST_REVIEW_SOLUTION, listApisReviewTypeSolutionSaga);
  yield takeEvery(API_ACTIONS.LIST_REVIEW_APISPEC, listApisReviewTypeAPISpecSaga);
  yield takeEvery(API_ACTIONS.POST_REVIEW, postReviewSaga);
}
export default apisReviewSaga;
