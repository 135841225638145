import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import cn from "classnames";
import Icon from "@mdi/react";
import styles from "./createReview.module.scss";
import { mdiBackspaceReverse, mdiSend } from "@mdi/js";
import { FormReview } from "../../components/FormReviewException/FormReview.component";
import { FormReview as NewFormReview } from "../../components/FormReviewCreate/FormReview.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import { Loader } from "../../components/Loader/Loader.component";
import { ApiReview, ApiReviewTogether } from "../../types/ApiReview";
import {
  PillMessages,
  PostMessage,
  isRoleAllowedMessage,
} from "../../constants/labels";
import { NAV_SECTIONS, ROUTE_ENTITIES } from "../../constants/routes";
import { DeploymentTarget } from "../../types/ApiException";
import { Api } from "../../types/Api";

export interface CreateReviewComponentProps {
  apisReviewTypeSolution: ApiReview[];
  apisReviewTypeApiSpec: ApiReview[];
  apis: Api[];
  deploymentTarget: DeploymentTarget[];
  loading: boolean;
  reviewMessage: string;
  loadMessage: string;
  isRoleAllowed: string;
  onSubmit: any;
  selectedReview: string;
  setSelectedReview: Function;
}

export const CreateReviewComponent: React.FC<CreateReviewComponentProps> = ({
  apisReviewTypeSolution,
  apisReviewTypeApiSpec,
  apis,
  deploymentTarget,
  loading,
  reviewMessage,
  loadMessage,
  isRoleAllowed,
  onSubmit,
  selectedReview,
  setSelectedReview,
}) => {
  const handleCancel = () => {
    window.location.reload();
  };

  const methods = useForm<ApiReviewTogether>();
  const ICON_SIZE = "1rem";
  const checkIsRoleAllowed = isRoleAllowed === isRoleAllowedMessage.Allowed;

  const reviewsName = [
    { interface: "review", name: "MuleSoft Review" },
    { interface: "exception", name: "MuleSoft Exceptions" },
  ];

  if (loading || isRoleAllowed === "") {
    return <Loader />;
  }

  return (
    <div className={styles.createReview__wrapper}>
      <div className={styles.createReview_title}>New C4E Review</div>

      {loadMessage && !loading && (
        <div className={styles.createReview_message}>
          <PillLabel
            variant={PillVariants.ERROR}
            text={PillMessages.apiNotLoaded}
          />
        </div>
      )}

      {!checkIsRoleAllowed && (
        <div className={styles.createReview_message}>
          <PillLabel
            variant={PillVariants.WARNING}
            text={isRoleAllowedMessage.HigherPermissions}
          />
        </div>
      )}

      {reviewMessage.length > 0 &&
      reviewMessage === PostMessage.errorSubmitted ? (
        <>
          <div className={styles.createReview_message_submit}>
            <div className={styles.createReview_message_submit_inner}>
              <PillLabel variant={PillVariants.ERROR} text={reviewMessage} />
              <a
                href={`${NAV_SECTIONS.governance}${ROUTE_ENTITIES.create}`}
                className={styles.link_refresh}
              >
                Create New Review ➜
              </a>
            </div>
          </div>
        </>
      ) : (
        reviewMessage.length > 0 &&
        reviewMessage === PostMessage.successfulSubmitted && (
          <>
            <div className={styles.createReview_message_submit}>
              <div className={styles.createReview_message_submit_inner}>
                <PillLabel variant={PillVariants.INFO} text={reviewMessage} />
                <a
                  href={`${NAV_SECTIONS.governance}${ROUTE_ENTITIES.create}`}
                  className={styles.link_refresh}
                >
                  Create New Review ➜
                </a>
              </div>
            </div>
          </>
        )
      )}

      {reviewMessage ? (
        ""
      ) : !loadMessage && !loading && !!checkIsRoleAllowed ? (
        <div className={styles.createReview_card_main}>
          {reviewsName.map((review, index) => (
            <div
              key={index}
              onClick={() => setSelectedReview(review.interface)}
              className={cn(
                styles.createReview_card_single,
                `${
                  selectedReview === review.interface
                    ? styles.createReview_card_selected
                    : undefined
                }`
              )}
            >
              <div className={styles.createReview_card_review}>
                {review.name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
      {reviewMessage ? (
        ""
      ) : selectedReview === "" ? (
        ""
      ) : (
        <FormProvider {...methods}>
          <form id={selectedReview} className={styles.createReview_form}>
            {selectedReview === reviewsName[0].interface ? (
              <NewFormReview selectedReview={selectedReview} apis={apis}></NewFormReview>
            ) : selectedReview === reviewsName[1].interface ? (
              <FormReview
                selectedReview={selectedReview}
                apisReviewTypeSolution={apisReviewTypeSolution}
                apisReviewTypeApiSpec={apisReviewTypeApiSpec}
                deploymentTarget={deploymentTarget}
              />
            ) : (
              <div className={styles.apiList__messages}>
                <PillLabel
                  variant={PillVariants.ERROR}
                  text={PillMessages.formError}
                />
              </div>
            )}
            {selectedReview && (
              <div className={styles.createReview_container_button}>
                <button
                  onClick={handleCancel}
                  className={cn(
                    styles.createReview_button,
                    styles.createReview_button_cancel
                  )}
                >
                  Cancel
                  <Icon
                    path={mdiBackspaceReverse}
                    title="Cancel"
                    size={ICON_SIZE}
                  />
                </button>
                <button
                  onClick={methods.handleSubmit(onSubmit)}
                  form={selectedReview}
                  type="submit"
                  className={cn(
                    styles.createReview_button,
                    styles.createReview_button_submit
                  )}
                >
                  Submit
                  <Icon path={mdiSend} title="Submit" size={ICON_SIZE} />
                </button>
              </div>
            )}
          </form>
        </FormProvider>
      )}
    </div>
  );
};
