import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../constants/authConfig"; // Adjust the import path as needed

let msalInstance: PublicClientApplication | undefined;

function getMsalInstance(): PublicClientApplication {
  if (!msalInstance) {
    msalInstance = new PublicClientApplication(msalConfig);
  }
  return msalInstance;
}

export default getMsalInstance;
