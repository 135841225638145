// src/App.tsx
import { useEffect } from "react";
import styles from "./app.module.scss";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import { ROUTES } from "./constants/routes";
import { Home } from "./pages/Home";
import { Landing } from "./pages/Landing";
import { NavigationBar } from "./components/NavigationBar/NavigationBar.component";
import { APIList } from "./pages/APIList";
import { APIReviewList } from "./pages/APIReviewList";
import { APIDetails } from "./pages/APIDetails";
import { APIReviewDetails } from "./pages/APIReviewDetails";
import { ScoreDetails } from "./pages/ScoreDetails";
import { SystemDetails } from "./pages/SystemDetails";
import { CreateReview } from "./pages/CreateReview";
import { ReviewHistory } from "./pages/ReviewHistory";
import { ExceptionHistory } from "./pages/ExceptionHistory";
import {
  authLoadingSelector,
  isAuthenticatedSelector,
  authRoleMessageSelector,
  tokenSelector,
} from "./store/auth/selectors";
import { useDispatch, useSelector } from "react-redux";
import { authInitAction, authFetchRoleAction } from "./store/auth/actions";
import { Loader } from "./components/Loader/Loader.component";
import { isRoleAllowedMessage } from "./constants/labels";
import { ExceptionStatus } from "./pages/ExceptionStatus";

function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const token = useSelector(tokenSelector);
  const isRoleAllowed = useSelector(authRoleMessageSelector);
  const authLoading = useSelector(authLoadingSelector);

  useEffect(() => {
    dispatch(authInitAction());
    dispatch(authFetchRoleAction(token));
  }, [dispatch, token]);

  if (authLoading) {
    return <Loader />;
  }

  return (
    <Router>
      <div className={styles.layout}>
        {isAuthenticated ? <NavigationBar /> : <div></div>}
        <div className={styles.content}>
          <Routes>
            {!isAuthenticated && (
              <Route path={ROUTES.landing} element={<Landing />} />
            )}
            <Route path={ROUTES.home} element={<Home />} />
            <Route path={ROUTES.apiList} element={<APIList />} />
            <Route path={ROUTES.apiDetails} element={<APIDetails />} />
            <Route path={ROUTES.systemDetails} element={<SystemDetails />} />
            <Route path={ROUTES.scoreDetails} element={<ScoreDetails />} />
            <Route path={ROUTES.apiReviewList} element={<APIReviewList />} />
            <Route
              path={ROUTES.apiReviewDetails}
              element={<APIReviewDetails />}
            />
            <Route path={ROUTES.reviewHistory} element={<ReviewHistory />} />
            <Route
              path={ROUTES.exceptionHistory}
              element={<ExceptionHistory />}
            />
            <Route
              path={ROUTES.exceptionStatus}
              element={<ExceptionStatus />}
            />
            {isRoleAllowed === "" && (
              <Route path={ROUTES.create} element={<Loader />} />
            )}
            {isRoleAllowed === isRoleAllowedMessage.Allowed && (
              <Route path={ROUTES.create} element={<CreateReview />} />
            )}
            <Route
              path="*"
              element={
                <Navigate to={isAuthenticated ? ROUTES.home : ROUTES.landing} />
              }
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
