import React, { useEffect, useMemo, useState } from "react";
import { Loader } from "../../components/Loader/Loader.component";
import { PageHeader } from "../../components/PageHeader/PageHeader.component";
import { PillMessages, Placeholder } from "../../constants/labels";
import styles from "./reviewHistory.module.scss";
import { Pagination } from "../../components/Pagination/Pagination.component";
import { FilterCheckbox } from "../../components/FilterCheckbox/FilterCheckbox.component";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton.component";
import { DetailsReviewField } from "../../components/DetailsReviewField/DetailsReviewField.component";
import BorderedBox from "../../components/BorderedBox/BorderedBox.component";
import {
  PillLabel,
  PillVariants,
} from "../../components/PillLabel/PillLabel.component";
import { ApiReviewNewInterface } from "../../types/ApiReview";
import { FilterDropdown } from "../../components/FilterDropdown/FilterDropdown.component";

export interface ReviewHistoryComponentProps {
  apiLatestReview?: ApiReviewNewInterface[];
  apisReview: ApiReviewNewInterface[];
  apisReviewType: string[];
  apiLoading: boolean;
  selectedReviewType: string;
  setSelectedReviewType: Function;
}

export const ReviewHistoryComponent: React.FC<ReviewHistoryComponentProps> = ({
  apiLatestReview,
  apisReview,
  apisReviewType,
  apiLoading,
  selectedReviewType,
  setSelectedReviewType,
}) => {
  useEffect(() => {
    setApis(apisReview);
  }, [apisReview]);

  //Start Filter Approved
  let [apis, setApis] = useState(apisReview);
  const [checked, setChecked] = useState(false);

  const handleCheck = (checked: boolean) => {
    setCurrentPage(1);
    if (checked === true) {
      setChecked(true);
      const data = apisReview
        .filter((dataFilter) => dataFilter.approved === true)
        .map((filteredName) => {
          return filteredName;
        });
      setApis(data);
    } else {
      setChecked(false);
      setApis(apisReview);
    }
  };
  //End Filter Approved

  //Start Filter ReviewType
  apis = apis.filter((apiReviewType) => {
    if (selectedReviewType === "Solution Design") {
      return apiReviewType.reviewType === "Solution Design";
    } else if (selectedReviewType === "API Specification") {
      return apiReviewType.reviewType === "API Specification";
    } else if (selectedReviewType === "Code") {
      return apiReviewType.reviewType === "Code";
    } else return apis;
  });
  //End Filter ReviewType

  //Start Pagination
  const [currentPage, setCurrentPage] = useState(1);

  const apisPagination: ApiReviewNewInterface[] = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 2;
    const lastPageIndex = firstPageIndex + 2;
    return apis.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, apis]);
  //End Pagination

  if (apiLoading) {
    return <Loader />;
  } else if (
    (!apiLatestReview || !apisPagination.length || !apisReview.length) &&
    !apiLoading
  ) {
    <div>
      <PillLabel
        variant={PillVariants.ERROR}
        text={PillMessages.missingReview}
      />
    </div>;
  }

  return (
    <div className={styles.history_main_wrapper}>
      <div className={styles.history_header}>
        <PageHeader
          bookmark="API Reviews"
          title={apiLatestReview![0]?.apiId || Placeholder.NotAvailable}
        />
        <GoBackButton />
      </div>

      <>
        <div className={styles.history_filter}>
          <FilterCheckbox
            id="approved"
            title="Approved"
            name="approved"
            handleCheck={(e: any) => handleCheck(e.target.checked)}
          />
          <FilterDropdown
            nameDropdown="Review Type"
            handleDropdown={(e: any) =>
              setSelectedReviewType(
                e.target.value === "All" ? "" : e.target.value
              )
            }
            dropdownMap={["All", ...apisReviewType]}
            initialDropdown={"Review Type"}
            selectedDropdown={selectedReviewType}
          />
          <Pagination
            currentPage={currentPage}
            totalPages={Math.ceil(apis.length / 2)}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>

        {checked === true && !apis.length ? (
          <div>
            <PillLabel
              variant={PillVariants.ERROR}
              text={PillMessages.noApprovedFound}
            />
          </div>
        ) : (
          apisPagination?.map((apiReview, index) => (
            <BorderedBox>
              <DetailsReviewField apiLatestReview={apiReview} key={index} />
            </BorderedBox>
          ))
        )}
      </>
    </div>
  );
};
