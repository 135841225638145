import React, { useEffect, useRef, useState, ReactNode } from "react";
import cn from "classnames";
import styles from "./collapsible.module.scss";

export interface CollapsibleContentProps {
  collapsed: boolean;
  children: ReactNode;
}

export const CollapsibleContent: React.FC<CollapsibleContentProps> = ({
  collapsed,
  children,
}) => {
  const collapsibleRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState<string>("0");

  useEffect(() => {
    const currentElement = collapsibleRef.current;

    if (currentElement) {
      setMaxHeight(
        collapsed ? "0" : `${collapsibleRef.current.scrollHeight}px`
      );
    }

    const element = currentElement?.children.item(0);
    const resizeObserver = new ResizeObserver((_) => {
      if (element && currentElement) {
        setMaxHeight(collapsed ? "0" : `${currentElement.scrollHeight}px`);
      }
    });

    if (element) {
      resizeObserver.observe(element);
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element);
      }
    };
  }, [collapsibleRef, setMaxHeight, collapsed]);

  return (
    <div
      ref={collapsibleRef}
      className={cn(styles.collapsible, { [styles.collapsed]: collapsed })}
      style={{ maxHeight }}
    >
      {children}
    </div>
  );
};
