import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { withAuth } from "../../components/withAuth/withAuth";
import {
  apisLoadingSelector,
  selectedApiLatestReviewsNewSelector,
  selectedApiReviewsListSelector,
  selectedApiReviewTypeSelector
} from "../../store/apisReview/selectors";
import {
  fetchApiLatestReviewAction,
  fetchApiReviewListAction
} from "../../store/apisReview/actions";
import { ReviewHistoryComponent } from "./ReviewHistory.component";

const ReviewHistoryContainer = () => {
  const dispatch = useDispatch();

  interface Params {
    groupId: string;
    apiId: string;
  }

  const params = useParams<keyof Params>() as Params;

  const apiLatestReview = useSelector(selectedApiLatestReviewsNewSelector);
  const apisReview = useSelector(selectedApiReviewsListSelector);
  const apisReviewType = useSelector(selectedApiReviewTypeSelector);
  const [selectedReviewType, setSelectedReviewType] = useState("");

  const apiLoading = useSelector(apisLoadingSelector) || 
  (apiLatestReview && apiLatestReview.length > 0 ? 
    (apiLatestReview[0].groupId !== params.groupId || apiLatestReview[0].apiId !== params.apiId) : 
    false);

  useEffect(() => {
    if (apiLatestReview && apiLatestReview.length > 0) {
      if (!(apiLatestReview[0].groupId === params.groupId && apiLatestReview[0].apiId === params.apiId)) {
        dispatch(fetchApiLatestReviewAction(params.groupId, params.apiId));
        dispatch(fetchApiReviewListAction(params.groupId, params.apiId));
      } else {
        dispatch(fetchApiReviewListAction(params.groupId, params.apiId));
      }
    } else {
      dispatch(fetchApiLatestReviewAction(params.groupId, params.apiId));
      dispatch(fetchApiReviewListAction(params.groupId, params.apiId));
    }
  }, [dispatch, params.groupId, params.apiId, apiLatestReview]);

  const props = {
    apiLatestReview,
    apisReview,
    apisReviewType,
    apiLoading,
    selectedReviewType,
    setSelectedReviewType,
  };

  return <ReviewHistoryComponent {...props} />;
};

export default withAuth(ReviewHistoryContainer);
