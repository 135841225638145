import {
  AuthenticationResult
} from "@azure/msal-browser";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { acquireTokenRequest } from "../../constants/authConfig";
import { setLocationPath } from "../../utils/session";
import {
  authMissingAuthAction,
  authSetAccountInfoAction,
  authSetTokenAction,
  authSetRoleMessageAction,
  AuthFetchOwnerRoleActionType,
  AUTH_ACTIONS,
} from "./actions";
import { tokenSelector } from "../../store/auth/selectors";
import { getRole } from "./requests";
import { isRoleAllowedMessage } from "../../constants/labels";
import getMsalInstance from "../../utils/msalInstance"; // Adjust the import path as needed

async function acquireToken() {
  const msalInstance = getMsalInstance();
  const authResult = await msalInstance.handleRedirectPromise();

  const account = authResult?.account || msalInstance.getAllAccounts()[0];

  if (account) {
    msalInstance.setActiveAccount(account);
    return await msalInstance.acquireTokenSilent(acquireTokenRequest);
  }
  throw new Error("No active account set!");
}

function* initSaga() {
  try {
    const msalInstance = getMsalInstance();
    const authenticationResult: AuthenticationResult = yield call(acquireToken);

    yield put(authSetTokenAction(authenticationResult.accessToken));
    yield put(
      authSetAccountInfoAction(
        authenticationResult.account || msalInstance.getAllAccounts()[0]
      )
    );
  } catch (e) {
    yield put(authMissingAuthAction());
  }
}

async function loginSaga() {
  const msalInstance = getMsalInstance();
  setLocationPath();
  await msalInstance.loginRedirect(acquireTokenRequest);
}

export function* fetchRole(token: AuthFetchOwnerRoleActionType) {
  try {
    while (token.payload === undefined || token.payload === "") {
      token = yield select(tokenSelector);
    }

    const result: string = yield call(getRole, token.payload);
    yield put(authSetRoleMessageAction(isRoleAllowedMessage.Allowed));
    return result;
  } catch (e) {
    yield put(authSetRoleMessageAction(isRoleAllowedMessage.Denied));
  }
}

function* authSaga() {
  yield takeEvery(AUTH_ACTIONS.INIT, initSaga);
  yield takeEvery(AUTH_ACTIONS.LOGIN, loginSaga);
  yield takeEvery(AUTH_ACTIONS.FETCH_OWNER_ROLE, fetchRole);
}

export default authSaga;
