import { Configuration, SilentRequest } from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AAD_APP_ID || "",
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AAD_TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    secureCookies: true,
  },
};

const REDIRECT_LOCATION_LABEL = "REDIRECT_LOCATION";

const loginRequest = {
  scopes: ["User.Read"],
};

const acquireTokenRequest: SilentRequest = {
  ...loginRequest,
  redirectUri: window.location.origin,
};

export {
  msalConfig,
  loginRequest,
  acquireTokenRequest,
  REDIRECT_LOCATION_LABEL,
};
