import React, { useCallback } from "react";
import styles from "./pagination.module.scss";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (nextPage: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const handlePrevious = useCallback(() => {
    onPageChange(currentPage - 1);
  }, [onPageChange, currentPage]);

  const handleNext = useCallback(() => {
    onPageChange(currentPage + 1);
  }, [onPageChange, currentPage]);

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.previous}
        onClick={handlePrevious}
        disabled={currentPage === 1}
      >
        <Icon path={mdiChevronLeft} title={"Previous"} size={"1.5rem"} />
      </button>
      <div className={styles.label_wrapper}>
        <div className={styles.page}>{`${currentPage} of ${totalPages}`}</div>
      </div>
      <button
        className={styles.next}
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        <Icon path={mdiChevronRight} title={"Next"} size={"1.5rem"} />
      </button>
    </div>
  );
};

export { Pagination };
