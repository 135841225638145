export const TitleLabels = {
  governance: "Governance",
  reviews: "Reviews",
  createReviews: "Create Reviews",
  support: "Support",
};

export const APILabels = {
  id: "ID",
  groupId: "Group ID",
  name: "Name",
  version: "Version",
  systemCode: "System Code",
  systemName: "System",
  platformName: "Platform",
  deepProduct: "DEEP Product",
  owner: "Owner",
  enabler: "Enabler",
  compliance: "Score",
  url: "URL",
  creationDate: "Created at",
  assetVersion: "Asset Version",
  appCount: "App Count",
  layer: "Layer",
  environments: "Environments",
  apiPlatform: "API Platform",
};

export const MergeAPILabels = {
  id: "ID",
  groupId: "Group ID",
  name: "Name",
  version: "Version",
  systemCode: "System Code",
  systemName: "System",
  platformName: "Platform",
  deepProduct: "DEEP Product",
  owner: "Owner",
  enabler: "Enabler",
  compliance: "Score",
  url: "URL",
  approved: "Approved",
  creationDate: "Created at",
  assetVersion: "Asset Version",
  appCount: "App Count",
  layer: "Layer",
  environments: "Environments",
  apiPlatform: "API Platform",
};

export const APIExceptionLabels = {
  exceptionId: "Exception ID",
  assetId: "Asset ID",
  assetVersion: "Asset Version",
  artefactId: "Artifact ID",
  codeVersion: "Code Version",
  deepProduct: "Deep Product",
  solutionDesignPageId: "Solution Page ID",
  groupId: "Group ID",
  version: "Version",
  jiraTicketId: "Jira Ticket ID",
  solutionUrl: "Solution URL",
  approved: "Approved",
  approvalDate: "Approval Date",
  approverId: "Approver",
  lastUpdateDate: "Last Update Date",
  lastUpdatedBy: "Last Update By",
  approvalDescription: "Approval Description",
  approvalEndDate: "Approval End Date",
  approvedWorkerSize: "Approved Worker Size",
  approvedWorkerNumber: "Approved Worker Number",
  approvedReplicas: "Approved Replicas",
  approvedMemory: "Approved Memory",
  approvedCpuLimit: "Approved CPU Limit",
  approvedCpuReserved: "Approved CPU Reserved",
  approvedDeploymentTarget: "Deployment Target",
  sizingException: "Sizing Exception",
  apiSpecificationException: "API Specification Exception",
  apiCodeException: "API Code Exception",
  solutionDesignException: "API Solution Design Exception",
};

export const APIExceptionStatusLabels = {
  uniqueId: "Unique ID",
  assetId: "Asset ID",
  assetVersion: "Asset Version",
  status: "Status",
  environment: "Environment",
  exception: "Exception Status",  
  approvedSizing: {
    workerSize: "Worker Size",
    workerNumber: "Worker Number",
    replicas: "Worker Number",
    cpuLimit: "CPU Limit",
    cpuReserved: "CPU Reserved",
    memory: "Memory", 
  },
  actualSizing: {
    workerSize: "Worker Size",
    workerNumber: "Worker Number",
    replicas: "Worker Number",
    cpuLimit: "CPU Limit",
    cpuReserved: "CPU Reserved",
    memory: "Memory", 
  }
};

export const APIExceptionStatusSizingLabels = {
  workerSize: "Worker Size",
  workerNumber: "Worker Number",
  replicas: "Worker Number",
  cpuLimit: "CPU Limit",
  cpuReserved: "CPU Reserved",
  memory: "Memory", 
};

export const SolutionDesignReviewLabels = {
  reviewId: "Review Id",
  pageId: "Page ID",
  version: "Version",
  solutionName: "Solution Name",
  deepProduct: "DEEP Product",
  groupId: "Group ID",
  solutionUrl: "Solution URL",
  jiraTicketId: "Jira Ticket",
  approved: "Approved",
  approvalDate: "Approval Date",
  approverId: "Approver",
  lastUpdateDate: "Last Updated Date",
  lastUpdatedBy: "Last Updated By",
  uniqueSolutionId: "Unique Solution ID",
};

export const APISpecReviewLabels = {
  reviewId: "Review Id",
  assetId: "Asset ID",
  solutionDesignPageId: "Solution Design Page ID",
  deepProduct: "DEEP Product",
  version: "Version",
  groupId: "Group ID",
  jiraTicketId: "Jira Ticket",
  approved: "Approved",
  approvalDate: "Approval Date",
  approverId: "Approver",
  lastUpdateDate: "Last Updated Date",
  lastUpdatedBy: "Last Updated By",
  qaReviewUrl: "QA Review URL",
  uniqueAssetId: "Unique Asset ID",
};

export const CodeReviewLabels = {
  reviewId: "Review Id",
  artefactId: "Artifact ID",
  solutionDesignPageId: "Solution Design Page ID",
  assetId: "Asset ID",
  deepProduct: "DEEP Product",
  version: "Version",
  groupId: "Group ID",
  jiraTicketId: "Jira Ticket",
  approved: "Approved",
  approvalDate: "Approval Date",
  approverId: "Approver",
  lastUpdateDate: "Last Updated Date",
  lastUpdatedBy: "Last Updated By",
  qaReviewUrl: "QA Review URL",
};

export const ReviewLabels = {
  assetId: "Asset ID",
  name: "Name",
  apiId: "API ID",
  version: "Version",
  reviewsId: "Review ID",
  solutionDocumentUrl: "Solution URL",
  qaReviewUrl: "QA Review URL",
  jiraTicket: "Jira Ticket",
  reviewType: "Review Type",
  approved: "Approved",
  assignee: "Assignee",
  groupId: "Group ID",
  approvedDate: "Approved Date",
  createdBy: "Created By",
  lastModifiedBy: "Last Modified By",
  createdDate: "Created Date",
  lastModifiedDate: "Last Modified Date",
}

export const SystemLabels = {
  systemId: "Id",
  name: "Name",
  scope: "Scope",
  hosting: "Hosting",
  platform: "Platform",
  serviceManager: "Service Manager",
  businessOwner: "Business Owner",
  type: "Type",
  systemCode: "Code",
  creationDate: "Created At",
  recommendation: "Recommendation",
  criticality: "Criticality",
  implementationStatus: "Status",
  id: "Code",
  isNorthStar: "Is North Star",
  isActual: "Is Actual",
  isOpen: "Is Open",
  openDate: "Open Date",
};

export const PillMessages = {
  missingSystem: "This API has no system associated yet.",
  missingReview: "This API has no previous reviews.",
  missingException: "This API has no previous exceptions.",
  pleaseSelectSystem: "Please select a system for your API:",
  apiNotLoaded: "Your APIs were not correctly loaded!",
  formError: "Something went wrong with the form!",
  noApprovedFound: "No Approved APIs found",
  searchApis: "We couldn't find any API based on your search!",
};

export const PostMessage = {
  successfulSubmitted: "Your Review was successful submitted",
  errorSubmitted: "There was a problem on submitting your review",
};

export const isRoleAllowedMessage = {
  Allowed: "Allowed Permissions",
  Denied: "Denied Permissions",
  HigherPermissions: "You need higher permissions for this page",
};

export const Placeholder = {
  NotAvailable: "N/A",
  Other: "Other",
};

export const BoolValueLabels = {
  True: "Yes",
  False: "No",
};

export const StatusValueLabels = {
  True: "True",
  False: "False",
  ToBeReviewed: "To be Reviewed",
};

export const SupportContacts = {
  Email: process.env.REACT_APP_SUPPORT_EMAIL,
  SigaRoleDEEPDesc: process.env.REACT_APP_SIGA_DEEP_ROLE_DESC,
  ImdlRoleViewerDesc: process.env.REACT_APP_IMDL_ROLE_VIEWER_DESC,
  ImdlRoleTeamDesc: process.env.REACT_APP_IMDL_ROLE_TEAM_DESC,
  SigaRoleReviewerDesc: process.env.REACT_APP_SIGA_ROLE_REVIEWER_DESC
};
