import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import {
  FormInputsAutoCompleteDropdown,
  FormInputsString,
  FormInputSwitch,
} from "../FormInputs/FormInputs.component";
import { Api } from "../../types/Api";

export interface FormReviewProps {
  selectedReview: string;
  apis: Api[];
}

export const FormReview: React.FC<FormReviewProps> = ({
  selectedReview,
  apis
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const optionsReviewType = [
    { value: "Solution Design", name: "Solution Design" },
    { value: "API Specification", name: "API Specification" },
    { value: "Code", name: "Application Code" },
  ];

  const [valueAssetID] = useState();
  const [reviewType, setReviewType] = useState("");

  return (
    <div>
      {selectedReview === "review" && (
        <>
          <FormInputsAutoCompleteDropdown
            name="reviewType"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            options={optionsReviewType}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              return option.name ? option.name : "Unknown";
            }}
            value={reviewType}
            isOptionEqualToValue={(option, value) => option.value === value}
            noOptionsText="Nothing Found"
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.name}
              </Box>
            )}
            label="Review Type"
            errors={errors}
            onChange={(select) => {
              setReviewType(select);
            }}
          />
          <FormInputsString
            name="jiraTicket"
            control={control}
            defaultValue={""}
            label="Jira Ticket"
            errors={errors}
            rules={{ required: true }}
            id="jiraTicket"
          />
          <FormInputsAutoCompleteDropdown
            name="assetId"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            options={apis}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              }
              return option.id ? option.id : "Unknown";
            }}
            value={valueAssetID}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            noOptionsText="Nothing Found"
            renderOption={(props, option) => (
              <Box component="li" {...props} key={props.id}>
                {option.id}
              </Box>
            )}
            label="Asset ID"
            errors={errors}
            onChange={(value) => value?.id}
            freeSolo
          />
          {reviewType === "Solution Design" && (
            <FormInputsString
              name="solutionName"
              control={control}
              defaultValue={""}
              label="Solution Name"
              errors={errors}
              rules={{ required: true }}
              id="solutionName"
            />
          )}
          <FormInputsString
            name="solutionDocumentUrl"
            control={control}
            defaultValue={""}
            label="Solution Document URL"
            errors={errors}
            rules={{ required: true }}
            id="solutionDocumentUrl"
          />
          <FormInputsString
            name="qaReviewUrl"
            control={control}
            defaultValue={""}
            label="QA Review URL"
            errors={errors}
            rules={{ required: true }}
            id="qaReviewUrl"
          />
          <FormInputsString
            name="version"
            control={control}
            defaultValue={""}
            label="Version"
            errors={errors}
            rules={{ required: true }}
            id="version"
          />
          <FormInputSwitch
            name="approved"
            control={control}
            defaultValue={false}
            label="Approved"
          />
        </>
      )}
    </div>
  );
};
