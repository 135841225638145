import { ApiException } from "../../types/ApiException";
import {
  ApiReview,
  ApiReviewNewInterface,
  ApiReviewTogether,
} from "../../types/ApiReview";

export enum API_ACTIONS {
  LIST_REVIEW = "##APIS/LIST_REVIEW",
  LIST_REVIEW_SOLUTION = "##APIS/LIST_REVIEW_SOLUTION",
  LIST_REVIEW_APISPEC = "##APIS/LIST_REVIEW_APISPEC",
  FETCH_LIST_REVIEW_BY_ID = "##APIS/FETCH_LIST_REVIEW_BY_ID",
  FETCH_ONE_LATEST_REVIEW = "##APIS/FETCH_ONE",
  POST_REVIEW = "##APIS/POST_REVIEW",
  SET_LIST_REVIEW = "##APIS/SET_LIST_REVIEW",
  SET_LIST_REVIEW_BY_ID = "##APIS/SET_LIST_REVIEW_BY_ID",
  SET_LIST_REVIEW_SOLUTION = "##APIS/SET_LIST_REVIEW_SOLUTION",
  SET_LIST_REVIEW_APISPEC = "##APIS/SET_LIST_REVIEW_APISPEC",
  SET_LIST_EXCEPTION_BY_ID = "##APIS/SET_LIST_EXCEPTION_BY_ID",
  SET_SELECTED_LATEST_REVIEW = "##APIS/SET_SELECTED_LATEST_REVIEW",
  SET_LOADING = "##APIS/SET_LOADING",
  SET_MESSAGE = "##APIS/SET_MESSAGE",
  SET_MESSAGE_LOAD = "##APIS/SET_MESSAGE_LOAD",
}

export interface ListApisReviewActionType {
  type: API_ACTIONS.LIST_REVIEW;
}

export interface ListApisReviewTypeSolutionActionType {
  type: API_ACTIONS.LIST_REVIEW_SOLUTION;
}

export interface ListApisReviewTypeAPISpecActionType {
  type: API_ACTIONS.LIST_REVIEW_APISPEC;
}

//Set to Payload
export interface SetListApisReviewActionType {
  type: API_ACTIONS.SET_LIST_REVIEW;
  payload: ApiReview[];
}

export interface SetListApisReviewTypeSolutionActionType {
  type: API_ACTIONS.SET_LIST_REVIEW_SOLUTION;
  payload: ApiReview[];
}

export interface SetListApisReviewTypeAPISpecActionType {
  type: API_ACTIONS.SET_LIST_REVIEW_APISPEC;
  payload: ApiReview[];
}

export interface PostActionType {
  type: API_ACTIONS.POST_REVIEW;
  payload: {
    data: ApiReviewTogether;
    token: string;
  };
}

export interface FetchApisReviewListByIdActionType {
  type: API_ACTIONS.FETCH_LIST_REVIEW_BY_ID;
  payload: {
    groupId: string;
    apiId: string;
  };
}

export interface FetchApiLatestReviewActionType {
  type: API_ACTIONS.FETCH_ONE_LATEST_REVIEW;
  payload: {
    groupId: string;
    apiId: string;
  };
}

export interface SetListApisReviewByIdActionType {
  type: API_ACTIONS.SET_LIST_REVIEW_BY_ID;
  payload: ApiReviewNewInterface[];
}

export interface SetListApisExceptionByIdActionType {
  type: API_ACTIONS.SET_LIST_EXCEPTION_BY_ID;
  payload: ApiException[];
}

export interface SetSelectedApiLatestReviewActionType {
  type: API_ACTIONS.SET_SELECTED_LATEST_REVIEW;
  payload: ApiReviewNewInterface[];
}

export interface SetApisReviewLoadingActionType {
  type: API_ACTIONS.SET_LOADING;
  payload: boolean;
}

export interface SetApisMessageActionType {
  type: API_ACTIONS.SET_MESSAGE;
  payload: string;
}

export interface SetApisMessageLoadActionType {
  type: API_ACTIONS.SET_MESSAGE_LOAD;
  payload: string;
}

//To be exported to APILISTV1 Container and use in Condition for Filter
export const listApisReviewAction = (): ListApisReviewActionType => ({
  type: API_ACTIONS.LIST_REVIEW,
});

export const listApisReviewTypeSolutionAction =
  (): ListApisReviewTypeSolutionActionType => ({
    type: API_ACTIONS.LIST_REVIEW_SOLUTION,
  });

export const listApisReviewTypeAPISpecAction =
  (): ListApisReviewTypeAPISpecActionType => ({
    type: API_ACTIONS.LIST_REVIEW_APISPEC,
  });

//Set Payload - Called on Saga
export const setListApisReviewAction = (
  apiReviews: ApiReview[]
): SetListApisReviewActionType => ({
  type: API_ACTIONS.SET_LIST_REVIEW,
  payload: apiReviews,
});

export const setListApisReviewTypeSolutionAction = (
  apiReviewsTypeSolution: ApiReview[]
): SetListApisReviewTypeSolutionActionType => ({
  type: API_ACTIONS.SET_LIST_REVIEW_SOLUTION,
  payload: apiReviewsTypeSolution,
});

export const setListApisReviewTypeAPISpecAction = (
  apiReviews: ApiReview[]
): SetListApisReviewTypeAPISpecActionType => ({
  type: API_ACTIONS.SET_LIST_REVIEW_APISPEC,
  payload: apiReviews,
});

export const postAction = (
  data: ApiReviewTogether,
  token: string
): PostActionType => ({
  type: API_ACTIONS.POST_REVIEW,
  payload: {
    data,
    token,
  },
});

export const fetchApiReviewListAction = (
  groupId: string,
  apiId: string
): FetchApisReviewListByIdActionType => ({
  type: API_ACTIONS.FETCH_LIST_REVIEW_BY_ID,
  payload: {
    groupId,
    apiId,
  },
});

export const fetchApiLatestReviewAction = (
  groupId: string,
  apiId: string
): FetchApiLatestReviewActionType => ({
  type: API_ACTIONS.FETCH_ONE_LATEST_REVIEW,
  payload: {
    groupId,
    apiId,
  },
});

export const setSelectedApiLatestReviewAction = (
  apiReview: ApiReviewNewInterface[]
): SetSelectedApiLatestReviewActionType => ({
  type: API_ACTIONS.SET_SELECTED_LATEST_REVIEW,
  payload: apiReview,
});

export const setSelectedApisExceptionListByIdAction = (
  apiException: ApiException[]
): SetListApisExceptionByIdActionType => ({
  type: API_ACTIONS.SET_LIST_EXCEPTION_BY_ID,
  payload: apiException,
});

export const setSelectedApiReviewByIdAction = (
  apiReview: ApiReviewNewInterface[]
): SetListApisReviewByIdActionType => ({
  type: API_ACTIONS.SET_LIST_REVIEW_BY_ID,
  payload: apiReview,
});

export const setApisLoadingAction = (
  loading: boolean
): SetApisReviewLoadingActionType => ({
  type: API_ACTIONS.SET_LOADING,
  payload: loading,
});

export const setApisMessageAction = (
  message: string
): SetApisMessageActionType => ({
  type: API_ACTIONS.SET_MESSAGE,
  payload: message,
});

export const setApisMessageLoadAction = (
  message: string
): SetApisMessageLoadActionType => ({
  type: API_ACTIONS.SET_MESSAGE_LOAD,
  payload: message,
});

export type ApiActionsTypes =
  | ListApisReviewActionType
  | ListApisReviewTypeSolutionActionType
  | ListApisReviewTypeAPISpecActionType
  | SetListApisReviewActionType
  | SetListApisReviewTypeSolutionActionType
  | SetListApisReviewTypeAPISpecActionType
  | PostActionType
  | FetchApiLatestReviewActionType
  | FetchApisReviewListByIdActionType
  | SetSelectedApiLatestReviewActionType
  | SetListApisReviewByIdActionType
  | SetListApisExceptionByIdActionType
  | SetApisReviewLoadingActionType
  | SetApisMessageActionType
  | SetApisMessageLoadActionType;