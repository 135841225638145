import React, { useCallback } from "react";
import styles from "./landing.module.scss";
import { ReactComponent as Logo } from "../../assets/img/logo.dark.svg";
import { CTAButton } from "../../components/CTAButton/CTAButton.component";
import { SupportContacts } from "../../constants/labels";
import { useDispatch } from "react-redux";
import { authLoginAction } from "../../store/auth/actions";
import useCollapse from "react-collapsed";
import Icon from "@mdi/react";
import { mdiChevronUp, mdiChevronDown } from "@mdi/js";

const LandingComponent = () => {
  const dispatch = useDispatch();

  const handleLogin = useCallback(() => {
    dispatch(authLoginAction());
  }, [dispatch]);

  const config = {
    duration: 1500,
    expandStyles: { opacity: "0.5" },
    collapseStyles: { opacity: "0.5" },
  };

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);

  return (
    <div className={styles.landing__wrapper}>
      <div className={styles.landing__header}>
        <Logo />
        <span className={styles.landing__title}>pen Access Platform</span>
        <CTAButton className={styles.landing__cta} onClick={handleLogin}>
          Login
        </CTAButton>
      </div>
      <div className={styles.landing__content} {...getToggleProps()}>
        <span className={styles.content__power_bi_button}>
          Power BI Report{" "}
          <Icon
            path={isExpanded ? mdiChevronUp : mdiChevronDown}
            size={"1.5rem"}
          />
        </span>
      </div>
      <div className={styles.landing__content} {...getCollapseProps()}>
        <iframe
          className={styles.power_bi__iframe}
          title="PowerBI Report"
          src={process.env.REACT_APP_POWER_BI_URL}
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </div>
      <div className={styles.landing__footer}>
        <div className={styles.footer__left}>
          <Logo />
        </div>
        <div className={styles.footer__right}>
          <dl>
            <dt>SIGA roles:</dt>
            <dd>
              DEEP OAP Roles{" "}
              <span className={styles.imdl_roles__desc}>
                {SupportContacts.SigaRoleDEEPDesc}
              </span>
            </dd>
            <dd>
              OAP VIEWER{" "}
              <span className={styles.imdl_roles__desc}>
                {SupportContacts.ImdlRoleViewerDesc}
              </span>
            </dd>
            <dd>
              OAP MEMBER{" "}
              <span className={styles.imdl_roles__desc}>
                {SupportContacts.ImdlRoleTeamDesc}
              </span>
            </dd>
            <dd>
              OAP Reviewer{" "}
              <span className={styles.imdl_roles__desc}>
                {SupportContacts.SigaRoleReviewerDesc}
              </span>
            </dd>
            <dt>For help and support:</dt>
            <dd>C4E Team</dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export { LandingComponent };
