import { ApiReview } from "../../types/ApiReview";
import { ApiReviewNewInterface } from "../../types/ApiReview";
import { RootState } from "../rootReducer";

export const apiListReviewSelector = (state: RootState): ApiReview[] => {
  return state.apisReview.resultsReview;
};

export const apiListReviewTypeSolutionSelector = (state: RootState): ApiReview[] => {
  return state.apisReview.resultsReviewTypeSolution;
};

export const apiListReviewTypeAPISpecSelector = (state: RootState): ApiReview[] => {
  return state.apisReview.resultsReviewTypeAPISpec;
};

export const selectedApiLatestReviewsNewSelector = (
  state: RootState
): ApiReviewNewInterface[] | undefined => state.apisReview.selectedLatestReview;

export const selectedApiReviewsListSelector = (
  state: RootState
): ApiReviewNewInterface[] => state.apisReview.selectedReviewListById;

export const selectedApiReviewTypeSelector = (state: RootState): string[] =>
  state.apisReview.reviewTypeValue;

export const apisLoadingSelector = (state: RootState): boolean =>
  state.apisReview.loading;

export const apisReviewMessageSelector = (state: RootState): string =>
  state.apisReview.reviewMessage;

export const apisReviewMessageLoadSelector = (state: RootState): string =>
  state.apisReview.loadMessage;
