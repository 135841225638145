import React, { ReactNode } from "react";
import styles from "./borderedBox.module.scss";

interface BorderedBoxProps {
  children: ReactNode;
}

const BorderedBox: React.FC<BorderedBoxProps> = ({ children }) => (
  <div data-testid="BorderedBoxComponent" className={styles.bordered__wrapper}>
    {children}
  </div>
);

export default BorderedBox;
