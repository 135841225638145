import React, { MouseEventHandler, useCallback, useState } from "react";
import cn from "classnames";
import styles from "./infoTooltip.module.scss";

export interface InfotooltipProps {
  children: React.ReactElement<typeof TooltipHolder | typeof TooltipContent>[];
}

interface InfoTooltipComposition {
  Holder: React.FC<TooltipHolderProps>;
  Content: React.FC<TooltipContentProps>;
}

interface TooltipContextType {
  isVisible: boolean;
  toggleVisible?: MouseEventHandler;
}

const TooltipContext = React.createContext<TooltipContextType>({
  isVisible: false,
});

const InfoTooltip: React.FC<InfotooltipProps> & InfoTooltipComposition = ({
  children,
}) => {
  const [isVisible, setVisible] = useState(false);

  const handleToggle = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible, setVisible]);

  return (
    <TooltipContext.Provider value={{ isVisible, toggleVisible: handleToggle }}>
      <div className={cn(styles.wrapper)}>{children}</div>
    </TooltipContext.Provider>
  );
};

interface TooltipHolderProps {
  children: React.ReactNode;
}

const TooltipHolder: React.FC<TooltipHolderProps> = ({ children }) => {
  const { toggleVisible } = React.useContext(TooltipContext);
  return (
    <div className={styles.holder} onClick={toggleVisible}>
      {children}
    </div>
  );
};
InfoTooltip.Holder = TooltipHolder;

interface TooltipContentProps {
  children: React.ReactNode;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ children }) => {
  const { isVisible } = React.useContext(TooltipContext);
  return (
    <div className={cn(styles.content, { [styles.isVisible]: isVisible })}>
      {children}
    </div>
  );
};
InfoTooltip.Content = TooltipContent;

export { InfoTooltip };
